<template>
  <b-container id="spotlight-rules">
    <h1>
      <div class="d-flex align-items-end justify-content-between">
        Spotlight Rules

        <b-button variant="outline-primary" class="float-right" @click="openEditorModal()"
          >Create new rule</b-button
        >
      </div>
    </h1>
    <Busy v-if="busy" class="mt-4" primary text="Fetching rules..." />

    <div v-else>
      <b-row align-v="center" class="reducer-filter m-0 p-1 pt-3">
        <b-col cols="12" md="4">
          <b-form-group label="SUPPLIER / TERM SEARCH" label-for="spotlightSupplierSearch">
            <b-input
              id="spotlightSupplierSearch"
              v-model="searchInput"
              class="reducer-input reducer-search-input"
              placeholder="Search suppliers / matching terms"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="AREA SEARCH" label-for="spotlightAreaSearch">
            <multiselect
              id="spotlightAreaSearch"
              v-model="selectedAreas"
              :options="spotlightSectors"
              placeholder="Select area"
              multiple
              :searchable="false"
            />
          </b-form-group>
        </b-col>
        <b-button class="border clear-button" variant="light" size="sm" @click="clearFilter()">
          Clear
        </b-button>
      </b-row>

      <b-row align-v="end">
        <b-col>
          <p v-if="filteredRules.length">Showing {{ filteredRules.length }} rules</p>
          <p v-else-if="searchInput">No rules found for "{{ searchInput }}"</p>
          <p v-else>No rules found</p>
        </b-col>
        <b-col>
          <b-pagination
            class="reducer-pagination float-right my-2"
            :total-rows="filteredRules.length"
            :per-page="perPage"
            v-model="currentPage"
            :first-text="'\u00AB'"
            :prev-text="'\u2039'"
            :next-text="'\u203A'"
            :last-text="'\u00BB'"
            :ellipsis-text="'\u2026'"
          />
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="filteredRules"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        show-empty
      >
        <template #cell(spotlightRuleId)="row">
          <b-dropdown text="Actions" lazy size="sm">
            <b-dropdown-item @click="openEditorModal(row.item)">
              <font-awesome-icon :icon="['far', 'edit']" class="mr-1 text-muted" />
              Edit rule
            </b-dropdown-item>
            <b-dropdown-item
              @click="
                deleteRuleModal.show = true;
                deleteRuleModal.id = row.item.spotlightRuleId;
              "
              variant="danger"
            >
              <font-awesome-icon :icon="['fas', 'trash']" class="mr-1" />
              Delete rule
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #cell(supplierName)="row">
          <span v-if="row.item.supplierId && suppliers && !suppliers[row.item.supplierId]">
            {{ row.value }}
            <b-badge variant="danger" v-b-tooltip="'Supplier not found'" class="p-1" pill>
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
            </b-badge>
          </span>

          <b-link
            v-else
            :to="{ name: 'supplier-details', params: { supplierId: row.item.supplierId } }"
            target="_blank"
          >
            {{ row.value }}
          </b-link>
        </template>

        <template #head(supplyTypes)>
          Supply Type
          <font-awesome-icon
            :icon="['fas', 'info-circle']"
            class="ml-1"
            v-b-popover.hover.top="
              'Supply types are used in automation. AP will create a supply for the area when auto-publishing a report. If there is more than one type, the one in bold has preference.'
            "
          />
        </template>

        <template #cell(supplyTypes)="row">
          <div class="d-flex">
            <div v-for="type in row.value" :key="type">
              <b v-if="row.value.length > 1 && row.item.preferredSupplyType == type" class="ml-1">
                {{ typeDisplay(type) }}
              </b>
              <span v-else class="ml-1">{{ typeDisplay(type) }}</span>
            </div>
          </div>
        </template>

        <template #empty>
          <div class="text-center my-2">
            <p class="text-body mb-2">Nothing found... 🦗</p>
            <b-button variant="outline-primary" @click="openEditorModal()">
              Create new rule
            </b-button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="filteredRules.length > perPage"
        class="reducer-pagination float-right my-2"
        :total-rows="filteredRules.length"
        :per-page="perPage"
        v-model="currentPage"
        :first-text="'\u00AB'"
        :prev-text="'\u2039'"
        :next-text="'\u203A'"
        :last-text="'\u00BB'"
        :ellipsis-text="'\u2026'"
      />
    </div>
    <SpotlightRuleEditor
      :rule="ruleBeingEdited"
      :suppliers="suppliers"
      :busy-suppliers="busySuppliers"
      @save-rule="updateRule"
    />
    <b-modal v-model="deleteRuleModal.show" @ok="deleteSpotlightRule" hide-header>
      Are you sure you would like to delete this rule? This action is irreversible.
    </b-modal>
  </b-container>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import Busy from "@/components/Busy";
import TypeHelper from "@/helper/typehelper";
import SupplyTypeHelper from "@/helper/supplytypehelper";
import SpotlightRuleEditor from "@/components/spotlight/SpotlightRuleEditor";

export default {
  name: "SpotlightRules",
  components: { Busy, SpotlightRuleEditor },
  data() {
    return {
      busy: true,
      spotlightRules: [],

      searchInput: null,
      selectedAreas: [],
      spotlightSectors: [],

      busySuppliers: false,
      suppliers: null,

      perPage: 50,
      currentPage: 1,

      ruleBeingEdited: null,
      deleteRuleModal: {
        show: false,
        id: null
      },

      fields: [
        { key: "spotlightRuleId", label: "" },
        { key: "supplierName", label: "Supplier", sortable: true },
        { key: "sectors", label: "Area", formatter: (arr) => arr.toString(), sortable: true },
        { key: "supplyTypes", label: "Supply Types" },
        { key: "matchingTerms", label: "Matching Terms", formatter: (arr) => arr.toString() }
      ]
    };
  },
  computed: {
    filteredRules() {
      let rules = this.spotlightRules;
      if (this.searchInput && this.searchInput.length > 1) {
        rules = rules.filter((r) => {
          let includesSupplier =
            r.supplierName && r.supplierName.toLowerCase().includes(this.searchInput.toLowerCase());
          let includesTerm = r.termIndex.toLowerCase().includes(this.searchInput.toLowerCase());
          return includesSupplier || includesTerm;
        });
      }
      if (this.selectedAreas.length) {
        rules = rules.filter(
          (r) => r.sectors && r.sectors.some((s) => this.selectedAreas.includes(s))
        );
      }
      return rules;
    }
  },
  created() {
    this.fetchSpotlightRules();
    if (this.$route.query.q) {
      this.searchInput = this.$route.query.q;
    }
    this.spotlightSectors = TypeHelper.spotlightSectors;
  },
  methods: {
    typeDisplay: SupplyTypeHelper.toDisplay,

    async fetchSpotlightRules() {
      this.busy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}spotlightrules`)
        .then((response) => {
          Console.log("Spotlight rules response", response);
          this.spotlightRules = response.data.rules;
          // For easer lookup
          this.spotlightRules.map(
            (r) => (r.termIndex = r.matchingTerms ? r.matchingTerms.join() : "")
          );
        })
        .catch((e) => {
          Console.error(e);
          this.showToast("There was an issue fetching spotlight data.", "warning");
        })
        .finally(() => (this.busy = false));
    },

    async fetchSuppliers() {
      this.busySuppliers = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}suppliers?summarised=true`)
        .then((response) => {
          Console.log("Suppliers response", response);
          this.suppliers = response.data.suppliers;
        })
        .catch((e) => {
          Console.error(e);
          this.showToast("There was an issue fetching suppliers data.", "warning");
        })
        .finally(() => (this.busySuppliers = false));
    },

    async deleteSpotlightRule() {
      const client = await ApiHelper.http();
      await client
        .delete(`${ApiHelper.endPoint()}spotlightrules/${this.deleteRuleModal.id}`)
        .then(() => {
          this.showToast("Rule deleted successfully", "success");
          this.spotlightRules = this.spotlightRules.filter(
            (r) => r.spotlightRuleId != this.deleteRuleModal.id
          );
        })
        .catch((e) => {
          this.showToast("There was an issue deleting the rule", "warning");
          Console.error(e);
        })
        .finally(() => {
          this.deleteRuleModal.id = null;
        });
    },

    openEditorModal(rule) {
      if (!this.suppliers) {
        this.fetchSuppliers();
      }
      this.ruleBeingEdited = rule;
      this.$bvModal.show("spotlight-rule-editor");
    },

    updateRule(rule) {
      if (rule.new) {
        this.spotlightRules.unshift(rule);
        rule.termIndex = rule.matchingTerms.join();
      } else {
        let i = this.spotlightRules.findIndex((r) => r.spotlightRuleId == rule.spotlightRuleId);
        if (i >= 0) {
          this.$set(this.spotlightRules, i, rule);
        } else {
          this.fetchSpotlightRules();
        }
      }
      this.ruleBeingEdited = null;
    },

    clearFilter() {
      this.searchInput = null;
      this.selectedAreas = [];
      this.$router.replace({ query: null });
    },

    showToast(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#spotlight-rules {
  th[aria-colindex="5"],
  td[aria-colindex="5"] {
    max-width: 20rem !important;
    word-wrap: break-word;
  }
  .clear-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: white;
  }
  .reducer-filter {
    position: relative;
  }
}
</style>
