<template>
  <b-modal
    v-model="show"
    id="spotlight-rule-editor"
    size="lg"
    ok-title="Save"
    :ok-disabled="!hasChanges || formBusy"
    @shown="updateForm()"
    @ok.prevent="submitChanges()"
    @hidden="resetForm()"
  >
    <b-overlay v-if="show" :show="busySuppliers || formBusy">
      <b-form ref="ruleForm">
        <b-row>
          <b-col>
            <div>
              <label class="text-body w-100">
                <div class="d-flex justify-content-between align-items-end">
                  <span>Supplier</span>
                  <span v-if="!isValidSupplier && !ruleForm.supplierId" class="text-danger small"
                    >Supplier {{ rule.supplierName }} not found and was removed</span
                  >
                  <b-link
                    v-else-if="ruleForm.supplierId"
                    :to="{ name: 'supplier-details', params: { supplierId: ruleForm.supplierId } }"
                    target="_blank"
                    class="small"
                  >
                    supplier details
                    <font-awesome-icon :icon="['fas', 'external-link']" class="ml-1" />
                  </b-link>
                </div>
              </label>
              <multiselect
                v-model="ruleForm.supplierId"
                :options="busySuppliers ? [] : Object.keys(suppliers)"
                placeholder="Select supplier"
                :custom-label="id => (busySuppliers ? null : suppliers[id].name)"
                @input="setSupplierName()"
              />
              <b-form-invalid-feedback
                v-if="ruleForm.supplierId || !formValid"
                :state="!ruleAlreadyExists"
              >
                A rule with matching supplier/sector already exists!
              </b-form-invalid-feedback>
            </div>
          </b-col>

          <b-col>
            <b-form-group label="Spotlight Area*">
              <multiselect
                :value="ruleForm.sectors"
                :options="spotlightSectors"
                placeholder="Select area"
                :searchable="false"
                @input="setSectors"
              />
              <b-form-invalid-feedback
                v-if="!formValid"
                :state="ruleForm.sectors != null && ruleForm.sectors.length != 0"
              >
                Please fill in this field
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Supply Types">
              <multiselect
                v-model="ruleForm.supplyTypes"
                :options="supplyTypes"
                multiple
                :custom-label="supplyTypeToDisplay"
                placeholder="Select types"
                :searchable="false"
                :allow-empty="false"
                @input="setPreferredType()"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Preferred Supply Type">
              <multiselect
                :disabled="ruleForm.supplyTypes && ruleForm.supplyTypes.length < 2"
                v-model="ruleForm.preferredSupplyType"
                :options="supplyTypes"
                :custom-label="supplyTypeToDisplay"
                placeholder="Select types"
                :searchable="false"
                :allow-empty="false"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <div>
          <div class="d-flex justify-content-between align-items-end">
            <div class="terms-header py-1 px-2">
              <span>Matching Terms*</span>
              <b-button
                size="sm"
                class="ml-2"
                v-b-tooltip.right="'New matching term'"
                @click="
                  ruleForm.matchingTerms
                    ? ruleForm.matchingTerms.push('')
                    : (ruleForm.matchingTerms = [''])
                "
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
              </b-button>
            </div>
            <b-button
              class="border text-danger"
              variant="light"
              target="_blank"
              href="https://reducr.atlassian.net/wiki/spaces/OP/pages/875659265/Spotlight+Rule+Writing"
              size="sm"
            >
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
              Rules and Tips
            </b-button>
          </div>

          <b-alert
            v-if="!formValid && ruleForm.matchingTerms.length < 1"
            show
            variant="warning"
            class="mt-1"
          >
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="mr-1" />
            Please add at least one matching term.
          </b-alert>

          <b-input-group
            v-for="(term, i) in ruleForm.matchingTerms"
            :key="i"
            class="mt-1 term-group"
          >
            <b-form-input
              v-model="ruleForm.matchingTerms[i]"
              required
              :placeholder="
                !i && ruleForm.supplierName ? ruleForm.supplierName.replaceAll(' ', '+') : ''
              "
            />
            <b-button
              class="p-0 text-danger border"
              variant="light"
              @click="ruleForm.matchingTerms.splice(i, 1)"
              v-b-tooltip.hover.top="'Delete matching term'"
            >
              <font-awesome-icon class="mx-2" :icon="['fal', 'trash-alt']" />
            </b-button>
          </b-input-group>
        </div>
      </b-form>
      <template #overlay>
        <Busy v-if="busySuppliers" primary text="Fetching suppliers... 🤸🏼‍♀️" />
        <Busy v-else-if="formBusy" primary text="Doing backend stuff... 🧙" />
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import TypeHelper from "@/helper/typehelper";
import { EventBus } from "@/components/eventbus";
import SupplyTypeHelper from "@/helper/supplytypehelper";
import Busy from "@/components/Busy";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import IDHelper from "@/helper/idhelper";

export default {
  name: "SpotlightRuleEditor",
  components: { Busy },
  props: {
    rule: Object,
    suppliers: Object,
    busySuppliers: Boolean
  },
  data() {
    return {
      show: false,
      ruleForm: {
        sectors: [],
        supplyTypes: [],
        preferredSupplyType: null,
        matchingTerms: []
      },

      formBusy: true,
      formValid: true,

      spotlightSectors: [],
      supplyTypes: []
    };
  },
  computed: {
    hasChanges() {
      const equals = (a, b) => a && b && a.length === b.length && a.every((v, i) => v === b[i]);
      return (
        !this.rule ||
        this.rule.supplierId != this.ruleForm.supplierId ||
        !equals(this.rule.sectors, this.ruleForm.sectors) ||
        !equals(this.rule.supplyTypes, this.ruleForm.supplyTypes) || 
        !equals(this.rule.matchingTerms, this.ruleForm.matchingTerms) ||
        this.rule.preferredSupplyType != this.ruleForm.preferredSupplyType
      );
    },
    isValidSupplier() {
      if (
        !this.busySuppliers &&
        this.rule &&
        this.rule.supplierId &&
        !this.suppliers[this.rule.supplierId]
      ) {
        this.updateForm();
        return false;
      }
      return true;
    },
    ruleAlreadyExists() {
      if (
        this.$parent.spotlightRules &&
        this.ruleForm.sectors &&
        this.ruleForm.sectors.length > 0
      ) {
        return this.$parent.spotlightRules.some(
          r =>
            r.sectors &&
            r.sectors[0] == this.ruleForm.sectors[0] &&
            this.ruleForm.supplierId == r.supplierId &&
            this.ruleForm.spotlightRuleId != r.spotlightRuleId
        );
      }
      return false;
    }
  },
  created() {
    this.spotlightSectors = TypeHelper.spotlightSectors;
    this.supplyTypes = SupplyTypeHelper.supplyTypes();
  },
  methods: {
    supplyTypeToDisplay: SupplyTypeHelper.toDisplay,

    submitChanges() {
      this.formValid =
        this.$refs.ruleForm.checkValidity() &&
        this.ruleForm.matchingTerms.length >= 1 &&
        this.ruleForm.sectors &&
        this.ruleForm.sectors.length >=1 &&
        !this.ruleAlreadyExists;
      if (!this.formValid) {
        this.$refs.ruleForm.reportValidity();
      } else if (this.hasChanges) {
        this.formBusy = true;
        this.saveRule();
      }
    },

    async updateForm() {
      this.formBusy = true;
      this.formValid = true;

      if (this.rule) {
        this.ruleForm = Object.assign({}, this.ruleForm, this.rule);
        if (this.rule.sectors) this.ruleForm.sectors = Array.from(this.rule.sectors);
        if (this.rule.supplyTypes) this.ruleForm.supplyTypes = Array.from(this.rule.supplyTypes);
        if (this.rule.matchingTerms)
          this.ruleForm.matchingTerms = Array.from(this.rule.matchingTerms);
        if (this.rule.supplierId && this.suppliers && !this.suppliers[this.rule.supplierId]) {
          this.ruleForm.supplierId = null;
          this.ruleForm.supplierName = null;
        }
      }
      this.formBusy = false;
    },

    async saveRule() {
      this.formBusy = true;
      this.setRuleId();
      const client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}spotlightrules`, {
          spotlightRules: [this.ruleForm]
        })
        .then(() => {
          this.showMessage("Rule saved successfully", "success");
          this.$emit("save-rule", this.ruleForm);
          this.show = false;
        })
        .catch(e => {
          this.showMessage("There was an issue saving the rule", "warning");
          Console.error(e);
        })
        .finally(() => (this.formBusy = false));
    },

    setRuleId() {
      if (!this.ruleForm.spotlightRuleId) {
        this.ruleForm.spotlightRuleId = IDHelper.generateUuid();
        this.ruleForm.new = true;
      }
    },

    setPreferredType() {
      if (this.ruleForm.supplyTypes.length == 1) {
        this.ruleForm.preferredSupplyType = this.ruleForm.supplyTypes[0];
      } else if (this.ruleForm.supplyTypes.length == 0) {
        this.ruleForm.preferredSupplyType = null;
      }
    },

    setSupplierName() {
      if (this.ruleForm.supplierId) {
        this.ruleForm.supplierName = this.suppliers[this.ruleForm.supplierId].name;
      } else {
        this.ruleForm.supplierName = null;
      }
    },

    setSectors(val) {
      if (val) {
        if (!Array.isArray(val)) this.ruleForm.sectors = [val];
        else this.ruleForm.sectors = val;
      } else {
        this.ruleForm.sectors = null;
      }
    },

    resetForm() {
      this.ruleForm = {
        sectors: [],
        supplyTypes: [],
        preferredSupplyType: null,
        matchingTerms: []
      };
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#spotlight-rule-editor {
  .terms-header {
    color: white;
    background-color: $color-blue-lighter2;
    border-radius: 5px;
    .btn {
      background-color: $color-blue-darker2;
      border-radius: 5px;
      font-size: 10px;
      color: white;
      border: none;
      &:hover {
        background-color: $color-blue-darker3;
      }
    }
  }
  .term-group {
    input {
      border-right: none;
    }
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
